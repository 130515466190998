<template>
  <div id="peasanthousehold-add">
    <v-container>
      <div class="operate d-flex justify-end">
        <div class="back">
          <v-btn
            dark
            color="primary"
            class="add align-self-center"
            @click="$router.go(-1)"
          >
            <v-icon dark> mdi-backburger </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="content">
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="data.name"
            :rules="nameRules"
            label="请填写姓名"
            required
          ></v-text-field>
          <v-text-field
            v-model="data.idCard"
            :rules="idCardRules"
            label="请填写身份证"
            required
            :error-messages="errorMessages.idCard"
          ></v-text-field>
          <v-text-field
            v-model="data.telephone"
            :rules="telephoneRules"
            label="请填写手机号"
            required
          ></v-text-field>
          <v-text-field
            v-model="data.addressDetail"
            label="请填写地址"
          ></v-text-field>
          <v-text-field
            v-model="data.remarks"
            label="请填写备注"
          ></v-text-field>
          <div class="d-flex justify-end">
            <v-btn class="px-16" color="success" x-large @click="save">
              保存
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    async save() {
      if (!this.$refs.form.validate()) return;

      (!this.id
        ? this.$shareyueApi.addPeasantHousehold(this.data)
        : this.$shareyueApi.editPeasantHousehold(this.id, this.data)
      )
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          if (!error) return;
          for (var key in error) {
            if (key in this.errorMessages) {
              this.errorMessages[key] = error[key];
            }
          }
        });
    },
    async load() {
      this.id = this.$route.query.id;
      if (!this.id) return;
      await this.$shareyueApi.getPeasantHousehold(this.id).then((data) => {
        Object.assign(
          this.data,
          (({ name, idCard, telephone, addressDetail, remarks }) => ({
            name,
            idCard,
            telephone,
            addressDetail,
            remarks,
          }))(data)
        );
      });
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      id: "",
      data: {
        name: "",
        idCard: "",
        telephone: "",
        addressDetail: "",
        remarks: "",
      },
      valid: true,
      errorMessages: {
        idCard: "",
      },
      nameRules: [(v) => !!v || "请填写姓名"],
      idCardRules: [
        (v) => !!v || "请填写身份证",
        (v) => this.$shareyue.checkIdCard(v) || "身份证格式错误",
      ],
      telephoneRules: [
        (v) => !!v || "请填写手机号",
        (v) => this.$shareyue.checkPhone(v) || "手机号格式错误",
      ],
    };
  },
};
</script>
<style lang="less" scoped>
</style>
